<template>
  <div class="containerx">
    <div class="search-box">
      <div style="margin-left: 20px;"></div>
      设备名称：<div style="width: 15rem; margin-right: 10px;"><el-input :clearable="true" v-model="queryForm.name"
          placeholder="请输入设备名称"></el-input></div>
      设备编号：<div style="width: 15rem;margin-right: 10px;"><el-input :clearable="true" v-model="queryForm.equipmentSn"
          placeholder="请输入设备编号"></el-input></div>
      <el-button @click="searchEquipment" type="primary">查询</el-button>
      <div class="export-box" style="margin-left: 0.6rem;">
      <el-button @click="exportss(0)" type="primary">导出设备</el-button>
      <el-button @click="exportss(2)" type="primary">导出环境数据</el-button>
    </div>
    </div>
    
    <div class="table-box">
      <el-table border :stripe="true" v-loading="loading" ref="multipleTable" :data="tableData" tooltip-effect="dark"
      style="width: 98%" @selection-change="handleSelectionChange">
        <el-table-column type="selection" width="55">
        </el-table-column>
        <el-table-column label="序号" type="index" width="50">
        </el-table-column>
        <el-table-column prop="name" label="设备名称" >
        </el-table-column>
        <el-table-column prop="serialNumber" label="设备编号"  show-overflow-tooltip>
        </el-table-column>
        <el-table-column prop="monitorSerialNumber" label="物联编号" show-overflow-tooltip>
        </el-table-column>
        <el-table-column prop="manufactor" label="生产厂家" show-overflow-tooltip>
        </el-table-column>
        <el-table-column prop="productionData" label="生产日期" show-overflow-tooltip>
        </el-table-column>

      </el-table>
    </div>

    <div class="page-box">
      <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange"
        :current-page.sync="queryForm.page" :page-size="queryForm.size" layout="prev, pager, next, jumper"
        :total="pageTotal">
      </el-pagination>
    </div>
  </div>
</template>

<script>
import * as echarts from 'echarts';
export default {
  name: 'index1',
  props: {
    // 项目id
    projectId: {
      type: Number,
      default: null
    },
    projects: {
      type: Number,
      default: null
    },
    // 层级id
    levelId: {
      type: Number,
      default: null
    }
  },
  components: {
  },
  data() {
    return {
      loading: false,
      queryForm: {
        page: 0,
        size: 6,
        type: 0,
        levelId: null,
        projectId: 1,
        name: null,
        equipmentSn: null
      },
      pageTotal: 0,
      tableData: [],
      // 设备id
      facilityId: 1,
      multipleSelection:[],
      selectEquipmentIds:[],
    }
  },
  methods: {
    //设备历史数据
    xq(item) {
        this.$router.push('/gjxx1?id=' + item.equipmentId + '&type=' + item.type)
      },
    toggleSelection(rows) {
      if (rows) {
        rows.forEach(row => {
          this.$refs.multipleTable.toggleRowSelection(row);
        });
      } else {
        this.$refs.multipleTable.clearSelection();
      }
    },
    handleSelectionChange(val) {
      this.multipleSelection = val;
      this.selectEquipmentIds = []
      val.forEach(item =>{
        this.selectEquipmentIds.push(item.id)
      })
    },
    //数据导出
    exportss(dataType) {
			if (this.selectEquipmentIds.length < 1) {
				this.$message({
					message: "请选择要导出的设备",
					type: 'error'
				});
				return;
			}
			var obj = {
				"levelId": this.levelId,
				"page": 1,
				"projectId": 0,
				"equipmentIds": this.selectEquipmentIds,
				"size": 10,
				"type": 0
			}
			this.$newPost('/equipment/export/' + dataType, obj).then(res => {
				if (dataType == 0) {
					if (res.success && res.data.excelUrl != null) {
						window.open(res.data.excelUrl)
					} else {
						this.$message({
							message: res.message,
							type: 'error'
						});
					}
				}  else if (dataType == 2 && res.success) {
					if (res.data.excelEnvironmentUrl != null) {
						window.open(res.data.excelEnvironmentUrl)
					} else {
						this.$message({
							message: "所选设备未上传数据",
							type: 'error'
						});
					}
				} else {
					this.$message({
						message: res.message,
						type: 'error'
					});
				}
			})
		},
    //全选/不选
    selectAll(res) {

    },
    changeEquipmentId(res, index) {
    },

  // 设备列表
  getFacility() {
    this.loading = true
    this.$newPost('/equipment/page', this.queryForm).then(res => {
      this.loading = false
      this.tableData = res.data.records
      this.pageTotal = res.data.total
      for (var i = 0; i < this.tableData.length; i++) {
        this.$set(this.tableData[i], 'checked', false)
      }

      this.facility_total = res.data.total
      if (res.data.records.length > 0) {
        this.facilityId = res.data.records[0].id
      }

    })
  },
  handleCurrentChange(e) {
    this.queryForm.page = e
    this.getFacility()
  },
  handleSizeChange(e) {
    this.queryForm.pageSize = e
    this.getFacility()
  },
  inputSn() {
    this.getFacility()
  },
  clearSn() {
    this.equipmentSn = null
    this.getFacility()
  },
  searchEquipment() {
    this.equipmentSn = null
    this.getFacility()
  }
},
mounted() {
  this.getFacility()
},
}
</script>

<style lang="scss" scoped>
.containerx {
  width: 100%;

  .search-box {
    display: flex;
    width: 100%;
    min-height: 8rem;
    align-items: center;
  }

  .table-box {
    width: 100%;
    display: flex;
    justify-content: center;
  }

  .page-box {
    width: 100%;
    display: flex;
    margin-top: 20px;
    justify-content: center;
  }

}
</style>
